/* Adicione este CSS ao seu arquivo de estilos */

@media only screen and (max-width: 768px) {
  .calendar-container {
    width: 100%;
    height: auto;
  }

  .rbc-toolbar {
    flex-direction: column;
  }

  .rbc-btn-group {
    margin: 5px 0;
  }

  .rbc-month-view {
    table-layout: fixed;
  }

  .rbc-event {
    font-size: 12px;
    padding: 2px;
  }

  .rbc-time-view .rbc-time-header,
  .rbc-time-view .rbc-time-content {
    flex-direction: column;
  }
}
