a:hover {
  color: #fff;
  /* Cor do texto no hover */
  background: #2c3e50; /* Cor de fundo no hover */
  color: #fff; /* Cor do texto (branco) no hover */
  /* Cor de fundo no hover */
  border-radius: 10%;
}

.active {
  font-weight: 400;
  background: #2c3e50; /* Cor de fundo no hover */
  color: #fff; /* Cor do texto (branco) no hover */
  border-radius: 10%;
}
